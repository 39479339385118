<div class="col-lg-5 container form-layout-custom">
    <div class="heading-form">
        {{ 'HOME.CONTACT.HEADING' | translate }}
    </div>
    <div class="home-navigation-newsletter">
      {{ 'HOME.CONTACT.CONTENT_PERSON' | translate }}
    </div>
    <div class="entry-inform">
      <fa-icon [icon]="faCaretRight" class="icon-inform"></fa-icon>
      {{ 'HOME.CONTACT.CONTENT_GENERAL' | translate }}
    </div>
    <div class="entry-inform">
      <fa-icon [icon]="faCaretRight" class="icon-inform"></fa-icon>
      {{ 'HOME.CONTACT.MAILTEXT' | translate }}
      <a href="mailto:{{ 'HOME.CONTACT.MAIL_CONTENT' | translate }}">
        <fa-icon [icon]="faEnvelope" class="icon-inform"></fa-icon>
        {{ 'HOME.CONTACT.MAIL_CONTENT' | translate }}
      </a>
    </div>
    <div class="home-navigation-newsletter margin-top-20">
        {{ 'HOME.CONTACT.TECHNICAL_PERSON' | translate }}
    </div>
    <div class="entry-inform">
        <fa-icon [icon]="faCaretRight" class="icon-inform"></fa-icon>
        {{ 'HOME.CONTACT.TECHNICAL_GENERAL' | translate }}
      </div>
      <div class="entry-inform">
        <fa-icon [icon]="faCaretRight" class="icon-inform"></fa-icon>
        {{ 'HOME.CONTACT.MAILTEXT' | translate }}
        <a href="mailto:{{ 'HOME.CONTACT.MAIL_TECHNICAL' | translate }}">
          <fa-icon [icon]="faEnvelope" class="icon-inform"></fa-icon>
          {{ 'HOME.CONTACT.MAIL_TECHNICAL' | translate }}
        </a>
      </div>

    <button type="button" routerLink="/" class="btn btn-info margin-top-20">{{ 'HOME.SUBSCRIBER.TOLANDINGPAGE' | translate }}</button>
  </div>
