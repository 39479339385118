import { SubscriberErrorMessages } from './subscriber-error-messages';
import { SubscriberValidators } from './../../shared/validators/subscriber.validator';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SubscriberService } from './../../shared/subscriber/subscriber.service';
import { SubscriberFactory } from './../../shared/subscriber/SubscriberFactory';
import { Component, OnInit } from '@angular/core';
import { faSave, faSync } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nl-subscribe',
  templateUrl: './subscribe.component.html'
})
export class SubscribeComponent implements OnInit {

  faSave = faSave;
  faSync = faSync;
  subscriber = SubscriberFactory.empty();
  errors: { [key: string]: string } = {};
  subscriberForm: FormGroup;
  failSave: Boolean;
  successSubscribe: Boolean;
  isLoadingSubscribe: Boolean;

  languageOptions = ['de', 'en'];

  constructor(
    private ss: SubscriberService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.failSave = false;
    this.initSubscriber();
  }

  initSubscriber() {
    this.subscriberForm = this.fb.group({
      first_name: [this.subscriber.first_name,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          SubscriberValidators.subscriberFirstNameFormat
        ]],
      name: [this.subscriber.name,
        [
          Validators.minLength(2),
          Validators.maxLength(50),
          SubscriberValidators.subscriberNameFormat
        ]],
      email: [this.subscriber.email,
        [
          Validators.required,
          Validators.email
        ]],
      language: [this.subscriber.language,
        [
          Validators.required
        ]],
      checked: [false,
        [
          Validators.requiredTrue
        ]]
    },
    {
      validator: SubscriberValidators.subscriberMailAvailable(this.ss, 'email', 'language')
    }
    );

    this.subscriberForm.statusChanges.subscribe(() => this.updateErrorMessages());
  }

  updateErrorMessages() {
    this.errors = {};
    for (const message of SubscriberErrorMessages) {
      const control = this.subscriberForm.get(message.forControl);
      if (control &&
          control.dirty &&
          control.invalid &&
          control.errors[message.forValidator] &&
          !this.errors[message.forControl]) {
        this.errors[message.forControl] = message.text;
      }
    }
  }

  submitFormSubscriber() {
    if (this.subscriberForm.valid) {
      const subscriber = SubscriberFactory.fromObject(this.subscriberForm.value);
      if (subscriber.email) {
        this.isLoadingSubscribe = true;
          this.ss.create(subscriber)
            .subscribe(
                data => {
                    this.failSave = false;
                    this.successSubscribe = true;
                    this.isLoadingSubscribe = false;
                },
                error => {
                  this.failSave = true;
                  this.successSubscribe = false;
                  this.isLoadingSubscribe = false;
                }
            );
      }
      this.subscriber = SubscriberFactory.empty();
      this.subscriberForm.reset(SubscriberFactory.empty());
    }
  }

}
