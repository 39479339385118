import { Observable } from 'rxjs';
import { NewsletterService } from './newsletter.service';
import { Newsletter } from './Newsletter';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NewsletterResolver implements Resolve<Newsletter> {

  constructor(private ns: NewsletterService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Newsletter> {
    return this.ns.getOne(route.params['id']);
  }
}
