<div class="language-wrapper">
  <div class="de_en" [class.active]="translate.currentLang === 'de'">
    <span>DE</span>
  </div>
  <div class="en_de" [class.active]="translate.currentLang === 'en'">
      <span>EN</span>
  </div>
  <label class="switch">
    <input type="checkbox" [checked]="translate.currentLang === 'en'" #langSelect (click)="updateLang(langSelect.checked)">
    <div class="slider round">
    </div>
  </label>
</div>
