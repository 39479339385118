import { Pictures } from './Pictures';
export class Entry {
  constructor(
    public _id: string,
    public name: string,
    public priority: number,
    public heading: string,
    public pictures: Pictures[],
    public teaser_text: string,
    public text: string,
    public category_id: string,
    public newsletter_id: string,
    public picture_author: string,
    public text_author: string
   ) {}

}
