import { Observable } from 'rxjs';
import { AuthService } from './../shared/authentication/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '../../../node_modules/@angular/router';

@Component({
  selector: 'nl-loginbutton',
  templateUrl: './loginbutton.component.html',
  styles: []
})
export class LoginbuttonComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
