<div class="col-lg-3 float-left div-navigation-home" [class.div-navigation-home-inactive]= "!showNavigation">
  <ul class="nav flex-column nav-left-home" [class.hide-nav-left-home]= "!showNavigation">
      <div class="heading-admin-navbar navigation-home-heading">
        <span class="icon-heading-admin-navbar"></span>
        {{ 'HOME.NAVIGATION' | translate }}
      </div>
      <li class="nav-item-home">
          <a class="home-navigation-newsletter" routerLink="/home/subscribe">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
              {{ 'HOME.SUBSCRIBE' | translate }}
          </a>
        </li>
      <li class="nav-item-home">
        <a class="home-navigation-newsletter" data-toggle="collapse" [attr.href]="'#actualnewsletters'">
            <span class="icon-navigation-home fa fa-angle-down"></span>
            {{ 'HOME.NEWSLETTER' | translate }}
        </a>
      </li>
      <div class="collapse show" id="actualnewsletters">
        <li *ngFor="let n of newsletters | slice:0:3" class="nav-item-home-sub" routerLink="/home/newsletter-detail/{{n._id}}">
            <a class="home-navigation-newsletter-sub">
              {{n.name}}
            </a>
        </li>
      </div>
      <li class="nav-item-home">
        <a class="home-navigation-newsletter collapsed" data-toggle="collapse" [attr.href]="'#archive'">
            <span class="icon-navigation-home fa fa-angle-down"></span>
            {{ 'HOME.ARCHIVE' | translate }}
        </a>
      </li>
      <div class="collapse" id="archive">
        <li *ngFor="let n of newsletters | slice:3" class="nav-item-home-sub" routerLink="/home/newsletter-detail/{{n._id}}">
            <a class="home-navigation-newsletter-sub">
              {{n.name}}
            </a>
        </li>
      </div>
      <li class="nav-item-home">
          <a class="home-navigation-newsletter" routerLink="/home/contact">
              <fa-icon [icon]="faEnvelope"></fa-icon>
              {{ 'HOME.CONTACT.HEADING' | translate }}
          </a>
        </li>
    </ul>
</div>
<div *ngIf="isLoading" class="loading-div col-lg-7 float-left">
    <fa-icon class="spin site-spin" [icon]="faSync"></fa-icon>
</div>
<div class="col-lg-7 float-left">
  <div *ngIf="(isLoading === false) && errorLoading && (entriesCount === 0); else entryData" class="container alert alert-danger col-md-8 margin-top-20 alert-home" role="alert">
      {{ 'HOME.FAILDATA' | translate }}
  </div>
  <ng-template #entryData>
      <div class="entries-home">
          <ng-container *ngFor="let e of entries | slice:startItem:endItem">
            <div class="single-entry-home" routerLink="/home/newsletter-detail/{{e.newsletter_id._id}}">
              <div class="single-entry-home-picture-div">
                <img class="single-entry-home-picture" src="{{api}}/pictures/{{e.pictures[0].title}}&260&195">
              </div>
              <div class="single-entry-home-text" >
                <div class="single-entry-home-newsletter" [innerHTML]="e.newsletter_id.name">
                </div>
                <div class="single-entry-home-date">
                  {{ 'HOME.PUBLISHEDON' | translate }} {{ e.newsletter_id.publishDate | localizedDate }}
                </div>
                <div class="entry-home-text-heading" [innerHTML]="e.heading" [style.color]="e.category_id.color">
                </div>
                <div [innerHTML]="e.teaser_text | safe: 'html'">
                </div>
                <div class="entry-home-text-readmore" [style.color]="e.category_id.color">
                    {{ 'HOME.READMORE' | translate }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="menu-right-table pagination-table">
          <div>
              {{ 'ADMIN.ENTRY.TEXTPAGINATION' | translate }}
              <select #paginationSelect class="select-pagination" (change)="updatePageSize(paginationSelect.value)" [value]="pageSize">
                  <option *ngFor="let p of pageSizeOptions" [value]="p" >{{p}}</option>
                </select>
          </div>
          <nav class="float-right page-buttons">
            <ul class="pagination">
              <li class="page-item" [class.disabled]="activePage === 0">
                <a class="page-link" (click)="changePage(-1)">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li *ngFor="let c of countPageButtonsArr | slice:startPage:endPage" class="page-item" [class.active]="c === activePage">
                <a #paginationPage class="page-link" (click)="updatePage(paginationPage.text)">{{ConvertToInt(c) + ConvertToInt(1)}}</a>
              </li>
              <li *ngIf="countPageButtonsArr" class="page-item" [class.disabled]="activePage === (countPageButtonsArr.length - 1)">
                <a class="page-link" (click)="changePage(1)">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
  </ng-template>

</div>
