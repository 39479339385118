import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { faChevronCircleRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nl-inform',
  templateUrl: './inform.component.html'
})
export class InformComponent implements OnInit {

  faChevronCircleRight = faChevronCircleRight;
  faPencilAlt = faPencilAlt;

  successSubscribe = false;
  successUnsubscribe = false;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const data = this.route.snapshot.data;
    if (data['subscribe']) {
      this.successSubscribe = true;
    }

    if (data['unsubscribe']) {
      this.successUnsubscribe = true;
    }
  }

}
