import { AuthGuard } from './../auth.guard';
import { NewsletterFactory } from './NewsletterFactory';
import { NewsletterRaw } from './newsletter-raw';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Newsletter } from './Newsletter';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    @Inject('API_URL') private api: string,
    private httpClient: HttpClient,
    private ag: AuthGuard) {
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  create(newsletter: Newsletter): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .post(`${this.api}/newsletter`, newsletter)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getAll(): Observable<Array<Newsletter>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<NewsletterRaw[]>(`${this.api}/newsletters`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawNewsletters => rawNewsletters
            .map(rawNewsletter => NewsletterFactory.fromObject(rawNewsletter)),
          ),
          catchError(this.handleError)
        );
    }
  }

  getAllNotPublished(): Observable<Array<Newsletter>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<NewsletterRaw[]>(`${this.api}/newslettersnotreadypublishing`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawNewsletters => rawNewsletters
            .map(rawNewsletter => NewsletterFactory.fromObject(rawNewsletter)),
          ),
          catchError(this.handleError)
        );
    }
  }

  nameAvailable(newsletter: string): Observable<Boolean> {
    if (this.ag.canActivateMethod()) {

      return this.httpClient
        .get<Boolean>(`${this.api}/newsletter/check/` + newsletter.replace('/', '%2F'))
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  remove(id: String): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .delete(`${this.api}/newsletter/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getOne(id: String): Observable<Newsletter> {
      return this.httpClient
        .get<NewsletterRaw>(`${this.api}/newsletter/${id}`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawNewsletter => NewsletterFactory.fromObject(rawNewsletter),
          ),
          catchError(this.handleError)
        );
  }

  update(newsletter: Newsletter): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .put(`${this.api}/newsletter/${newsletter._id}`, newsletter)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
    }
  }

  publishOneTest(id: String): Observable<Boolean> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<Boolean>(`${this.api}/newsletter/publish/${id}/test`)
        .pipe(
          catchError(this.handleError)
        );
      }
  }

  publishOne(id: String): Observable<Boolean> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<Boolean>(`${this.api}/newsletter/publish/${id}/prod`)
        .pipe(
          catchError(this.handleError)
        );
      }
  }
}
