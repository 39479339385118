<div class="col-lg-7 container form-layout-custom form-detail-entry">
  <div *ngIf="entry.text === ''; else dataAvailable" class="container alert alert-danger col-md-12 margin-top-20" role="alert">
      {{ 'HOME.DETAIL.NODATA' | translate }}
  </div>
  <ng-template #dataAvailable>
      <div class="print-detail-entry" *ngIf="newsletter">
        <a target="_blank" rel="noopener noreferrer" href="{{api}}/entry/pdf/{{entry._id}}">
          <button class="btn btn-print btn-preview">
            <fa-icon [icon]="faPrint"></fa-icon>
            {{ 'HOME.DETAIL.PRINTSINGLE' | translate }}
          </button>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="{{api}}/newsletter/pdf/{{entry.newsletter_id}}">
          <button class="btn btn-print btn-preview">
            <fa-icon [icon]="faPrint"></fa-icon>
            {{ 'HOME.DETAIL.PRINTCOMPLETE' | translate }}
          </button>
        </a>
      </div>
    <div class="first-section-entry">
      <div class="picture-div-entry">
        <div class="picture-container">
          <div class="picture-button-left" *ngIf="actualPicture > 0" (click)="changePicture(-1)">
            <fa-icon [icon]="faArrowAltCircleLeft" class="icon-picture-switch"></fa-icon>
          </div>
          <a target="_blank" rel="noopener noreferrer" href="{{api}}/pictures/{{entry.pictures[actualPicture].title}}&0&0">
            <img class="picture-detail-entry" src="{{api}}/pictures/{{entry.pictures[actualPicture].title}}&400&300">
          </a>
          <div class="picture-button-right" *ngIf="(actualPicture < (pictureCount - 1) ) && (pictureCount > 1)" (click)="changePicture(1)">
            <fa-icon [icon]="faArrowAltCircleRight" class="icon-picture-switch"></fa-icon>
          </div>
        </div>
        <div class="picture-subtext-entry" [innerHTML]="entry.pictures[actualPicture].subtext | safe: 'html'">
        </div>
      </div>
      <div [style.color]="category?.color">
        {{ category?.name }}
      </div>
      <div *ngIf="entry.heading !== ''" class="heading-detail-entry" [style.color]="category?.color">
        {{ entry.heading }}
      </div>
      <div class="teaser-detail-entry" [innerHTML]="entry.teaser_text | safe: 'html'"></div>
    </div>
    <div class="text-detail-entry" [innerHTML]="entry.text | safe: 'html'"></div>
    <div *ngIf="entry.picture_author || entry.text_author" class="author-section-detail-entry">
      <div *ngIf="entry.picture_author">
        <img class="picture-author-detail-entry" src="{{api}}/pictures/{{entry.picture_author}}&120&160">
      </div>
      <div *ngIf="entry.text_author" class="text-author-detail-entry" [innerHTML]="entry.text_author | safe: 'html'"></div>
    </div>
  </ng-template>
  <div class="buttons-preview-newsletter">
      <button type="button" routerLink="/" class="btn btn-info btn-preview"><fa-icon [icon]="faHome" class="margin-right-5"></fa-icon>{{ 'HOME.DETAIL.TOSTARTPAGE' | translate }}</button>
      <button type="button" routerLink="/home/subscribe" class="btn btn-info btn-preview"><fa-icon [icon]="faPencilAlt" class="margin-right-5"></fa-icon>{{ 'HOME.DETAIL.SUBSCRIBE' | translate }}</button>
      <button *ngIf="(isLoggedIn$ | async) === true" class="btn btn-info btn-preview" routerLink="/admin/dashboard/entry/view">
        {{ 'HOME.DETAIL.EDIT' | translate }}
      </button>
  </div>
</div>
