<div class="col-lg-7 margin-auto">
    <div class="newsletter-detail-above">
      <button type="button" routerLink="/" class="btn btn-info btn-preview float-right"><fa-icon [icon]="faHome" class="margin-right-5"></fa-icon>{{ 'HOME.DETAIL.TOSTARTPAGE' | translate }}</button>
        <div class="single-entry-home-newsletter" >
            {{ newsletter.name }}
            <a target="_blank" rel="noopener noreferrer" href="{{api}}/newsletter/pdf/{{newsletter._id}}">
                <fa-icon [icon]="faPrint"></fa-icon>
            </a>
        </div>
        <div class="single-entry-home-date">
          {{ 'HOME.PUBLISHEDON' | translate }} {{ newsletter.publishDate | localizedDate }}
        </div>
    </div>
    <div class="entries-home">
        <ng-container *ngFor="let e of entries | slice:startItem:endItem">
          <div class="single-entry-home" routerLink="/home/detail/{{e._id}}">
            <div class="single-entry-home-picture-div">
              <img class="single-entry-home-picture" src="{{api}}/pictures/{{e.pictures[0].title}}&260&195">
            </div>
            <div class="single-entry-home-text" >
              <div class="" [innerHTML]="e.category_id.name" [style.color]="e.category_id.color">
              </div>
              <div class="entry-home-text-heading" [innerHTML]="e.heading" [style.color]="e.category_id.color">
              </div>
              <div [innerHTML]="e.teaser_text | safe: 'html'">
              </div>
              <div class="entry-home-text-readmore" [style.color]="e.category_id.color">
                  {{ 'HOME.READMORE' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
    </div>
    <div class="menu-right-table pagination-table">
    <div>
        {{ 'ADMIN.ENTRY.TEXTPAGINATION' | translate }}
        <select #paginationSelect class="select-pagination" (change)="updatePageSize(paginationSelect.value)" [value]="pageSize">
            <option *ngFor="let p of pageSizeOptions" [value]="p" >{{p}}</option>
            </select>
    </div>
    <nav class="float-right page-buttons">
        <ul class="pagination">
        <li class="page-item" [class.disabled]="activePage === 0">
            <a class="page-link" (click)="changePage(-1)">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
            </a>
        </li>
        <li *ngFor="let c of countPageButtonsArr | slice:startPage:endPage" class="page-item" [class.active]="c === activePage">
            <a #paginationPage class="page-link" (click)="updatePage(paginationPage.text)">{{ConvertToInt(c) + ConvertToInt(1)}}</a>
        </li>
        <li *ngIf="countPageButtonsArr" class="page-item" [class.disabled]="activePage === (countPageButtonsArr.length - 1)">
            <a class="page-link" (click)="changePage(1)">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
            </a>
        </li>
        </ul>
    </nav>
      </div>
</div>