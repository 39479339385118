import { NewsletterService } from './../../shared/newsletter/newsletter.service';
import { Observable } from 'rxjs';
import { AuthService } from './../../shared/authentication/auth.service';
import { CategoryService } from './../../shared/category/category.service';
import { Category } from './../../shared/category/Category';
import { EntryFactory } from './../../shared/entry/EntryFactory';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { Newsletter } from 'src/app/shared/newsletter/Newsletter';
import { faPrint, faArrowAltCircleLeft, faArrowAltCircleRight, faHome, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nl-detail',
  templateUrl: './detail.component.html'
})
export class DetailComponent implements OnInit {

  faPrint = faPrint;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faArrowAltCircleRight = faArrowAltCircleRight;
  faHome = faHome;
  faPencilAlt = faPencilAlt;

  entry = EntryFactory.empty();
  category: Category;
  newsletter: Newsletter;
  isLoggedIn$: Observable<boolean>;
  pictureCount: Number = 1;
  actualPicture = 0;

  constructor(
    @Inject('API_URL') public api: string,
    private route: ActivatedRoute,
    private cs: CategoryService,
    private ns: NewsletterService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    const data = this.route.snapshot.data;
    if (data['entry']) {
      this.entry = data['entry'];
      this.cs.getOne(this.entry.category_id).subscribe(result => {
        this.category = result;
      });
      this.ns.getOne(this.entry.newsletter_id).subscribe(result => {
        this.newsletter = result;
      });
      if (this.entry.pictures.length > 1) {
        this.pictureCount = this.entry.pictures.length;
      }
    }
  }

  changePicture(num: number) {
    this.actualPicture += num;
  }

}
