import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SubscriberService } from './subscriber.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscribeUnsubscribeResolver {

  constructor(private ss: SubscriberService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Boolean> {
    return this.ss.unsubscribeOne(route.params['id']);
  }
}
