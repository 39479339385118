import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable, timeout } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  getNavigation(lang: string): Observable<any> {
    return this.httpClient
    .get('https://www.bonares.de/api/v1/main-nav-items?tags=wp-main-nav&locale=' + lang + '&workflowLocale=' + lang)
    .pipe(
      timeout(15000),
      catchError(this.handleError)
    );
  }
}
