import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '../../../../node_modules/@angular/common/http';
import { Observable } from '../../../../node_modules/rxjs';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) {}

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {


        const idToken = localStorage.getItem('id_token');

        if (idToken) {
          if (!this.auth.isTokenExpired()) {
            const cloned = req.clone({
              setHeaders: {
                Authorization: `Bearer ${idToken}`
              }
            });
            return next.handle(cloned);
           } else {
            return next.handle(req);
           }
          } else {
            return next.handle(req);
        }
    }
}
