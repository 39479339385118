import { AuthGuard } from './../auth.guard';
import { CategoryFactory } from './CategoryFactory';
import { CategoryRaw } from './category-raw';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Category } from './Category';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    @Inject('API_URL') private api: string,
    private httpClient: HttpClient,
    private ag: AuthGuard) {
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  create(category: Category): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .post(`${this.api}/category`, category)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getAll(): Observable<Array<Category>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<CategoryRaw[]>(`${this.api}/categories`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawCategories => rawCategories
            .map(rawCategory => CategoryFactory.fromObject(rawCategory)),
          ),
          catchError(this.handleError)
        );
    }
  }

  getAllSortPriority(): Observable<Array<Category>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<CategoryRaw[]>(`${this.api}/categories/priority`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawCategories => rawCategories
            .map(rawCategory => CategoryFactory.fromObject(rawCategory)),
          ),
          catchError(this.handleError)
        );
    }
  }

  nameAvailable(category: string): Observable<Boolean> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<Boolean>(`${this.api}/category/check/` + category.replace('/', '%2F'))
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  remove(id: String): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .delete(`${this.api}/category/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getOne(id: String): Observable<Category> {
    return this.httpClient
      .get<CategoryRaw>(`${this.api}/category/${id}`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawCategory => CategoryFactory.fromObject(rawCategory),
        ),
        catchError(this.handleError)
      );
  }

  update(category: Category): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .put(`${this.api}/category/${category._id}`, category)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
    }
  }



}
