import { NewsletterRaw } from './newsletter-raw';
import { Newsletter } from './Newsletter';
export class NewsletterFactory {

  static empty(): Newsletter {
    return new Newsletter('', '', 'de', '', false, false, null);
  }

  static fromObject(rawNewsletter: NewsletterRaw | any): Newsletter {
    return new Newsletter(
      rawNewsletter._id,
      rawNewsletter.name,
      rawNewsletter.language,
      rawNewsletter.picture,
      rawNewsletter.isPublished,
      rawNewsletter.readyForPublishing,
      rawNewsletter.publishDate
    );
  }
}
