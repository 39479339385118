import { SubscriberFactory } from './SubscriberFactory';
import { SubscriberRaw } from './subscriber-raw';
import { catchError, retry, map } from 'rxjs/operators';
import { Subscriber } from './Subscriber';
import { throwError, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(
    @Inject('API_URL') private api: string,
    private httpClient: HttpClient
    ) {
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  create(subscriber: Subscriber): Observable<any> {
    return this.httpClient
    .post(`${this.api}/subscriber`, subscriber)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAll(): Observable<Array<Subscriber>> {
    return this.httpClient
      .get<SubscriberRaw[]>(`${this.api}/categories`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawCategories => rawCategories
          .map(rawSubscriber => SubscriberFactory.fromObject(rawSubscriber)),
        ),
        catchError(this.handleError)
      );
  }

 mailAvailable(mail: string, language: string): Observable<Boolean> {
    return this.httpClient
      .get<Boolean>(`${this.api}/subscriber/check/${mail}/${language}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  remove(id: String): Observable<any> {
    return this.httpClient
    .delete(`${this.api}/subscriber/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  getOne(id: String): Observable<Subscriber> {
    return this.httpClient
      .get<SubscriberRaw>(`${this.api}/subscriber/${id}`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawSubscriber => SubscriberFactory.fromObject(rawSubscriber),
        ),
        catchError(this.handleError)
      );
  }

  confirmOne(id: String): Observable<Boolean> {
    return this.httpClient
      .get<Boolean>(`${this.api}/subscriber/confirm/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  unsubscribeOne(id: String): Observable<Boolean> {
    return this.httpClient
      .get<Boolean>(`${this.api}/subscriber/unsubscribe/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }
}
