import { EntryRaw } from './entry-raw';
import { Entry } from './Entry';
export class EntryFactory {

  static empty(): Entry {
    return new Entry('', '', 5, '', [{title: '', subtext: ''}], '', '', null, null, '', '');
  }

  static fromObject(rawEntry: EntryRaw | any): Entry {
    return new Entry(
      rawEntry._id,
      rawEntry.name,
      rawEntry.priority,
      rawEntry.heading,
      rawEntry.pictures,
      rawEntry.teaser_text,
      rawEntry.text,
      rawEntry.category_id,
      rawEntry.newsletter_id,
      rawEntry.picture_author,
      rawEntry.text_author
    );
  }
}
