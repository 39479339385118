import { NewsletterDetailComponent } from './home/newsletter-detail/newsletter-detail.component';
import { NewsletterResolver } from './shared/newsletter/newsletter-resolver.service';
import { ContactComponent } from './home/contact/contact.component';
import { SubscribeUnsubscribeResolver } from './shared/subscriber/subscribe-unsubscribe-resolver.service';
import { SubscribeConfirmResolver } from './shared/subscriber/subscribe-confirm-resolver.service';
import { InformComponent } from './home/inform/inform.component';
import { EntryResolver } from './shared/entry/entry-resolver.service';
import { DetailComponent } from './home/detail/detail.component';
import { SubscribeComponent } from './home/subscribe/subscribe.component';
import { AuthGuard } from './shared/auth.guard';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'home/subscribe',
    component: SubscribeComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./../app/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./../app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home/detail/:id',
    component: DetailComponent,
    resolve: {
    entry: EntryResolver
    }
  },
  {
    path: 'home/newsletter-detail/:id',
    component: NewsletterDetailComponent,
    resolve: {
    newsletter: NewsletterResolver
    }
  },
  {
    path: 'home/inform',
    component: InformComponent
  },
  {
    path: 'home/inform/subscribe/:id',
    component: InformComponent,
    resolve: {
      subscribe: SubscribeConfirmResolver
    }
  },
  {
    path: 'home/inform/unsubscribe/:id',
    component: InformComponent,
    resolve: {
      unsubscribe: SubscribeUnsubscribeResolver
    }
  },
  {
    path: 'home/contact',
    component: ContactComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
