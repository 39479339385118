import { SubscriberService } from './../subscriber/subscriber.service';
import { FormControl, FormGroup } from '@angular/forms';
export class SubscriberValidators {

  static subscriberFirstNameFormat(control: FormControl): { [error: string]: any } {
    if (!control.value) { return null; }

    const subscribernamePattern = /^[\wäöüÄÖÜß]+((( {1})|( ?\- ?))[\„\“\wäöüÄÖÜß]+)*$/;
    return subscribernamePattern.test(control.value) ? null : {
      subscriberfirstnameFormat: {valid: false}
    };
}

  static subscriberNameFormat(control: FormControl): { [error: string]: any } {
      if (!control.value) { return null; }

      const subscribernamePattern = /^[\wäöüÄÖÜß]+((( {1})|( ?\- ?))[\„\“\wäöüÄÖÜß]+)*$/;
      return subscribernamePattern.test(control.value) ? null : {
        subscribernameFormat: {valid: false}
      };
  }

  static subscriberMailAvailable(ss: SubscriberService, controlMail: string, controlLanguage: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlMail];
      const matchingControl = formGroup.controls[controlLanguage];

      if (control.errors) {
        if (control.errors.required || control.errors.email) {
          return;
        } else {
          if (control.value === '') {
            return;
          } else {
            if (!control.value || control.value === '') {
              return;
            } else {
              ss.mailAvailable(control.value, matchingControl.value)
              .subscribe(
                data => {
                  data ? control.setErrors(null) : control.setErrors({ mailAvailable: true });
                }
              );
            }
          }
        }
      } else {
        if (control.value === '') {
          return;
        } else {
          if (!control.value || control.value === '') {
            return;
          } else {
            ss.mailAvailable(control.value, matchingControl.value)
            .subscribe(
              data => {
                data ? control.setErrors(null) : control.setErrors({ mailAvailable: true });
              }
            );
          }
        }
      }
    };
  }
}
