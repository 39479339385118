import { Entry } from './Entry';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { EntryService } from './entry.service';

@Injectable({
  providedIn: 'root'
})
export class EntryResolver {

  constructor(private es: EntryService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Entry> {
    return this.es.getOne(route.params['id']);
  }
}
