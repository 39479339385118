import { CategoryRaw } from './category-raw';
import { Category } from './Category';
export class CategoryFactory {

  static empty(): Category {
    return new Category('', '', '');
  }

  static fromObject(rawCategory: CategoryRaw | any): Category {
    return new Category(
      rawCategory._id,
      rawCategory.name,
      rawCategory.color
    );
  }
}
