import { NavigationService } from './../shared/navigation.service';
import { faPencilAlt, faEnvelope, faAngleDoubleLeft, faSync, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { EntryService } from './../shared/entry/entry.service';
import { Newsletter } from './../shared/newsletter/Newsletter';

import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'nl-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {

  faPencilAlt = faPencilAlt;
  faEnvelope = faEnvelope;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faSync = faSync;
  faCaretRight = faCaretRight;

  entries: any[];
  newsletters: Newsletter[] = [];
  isLoading = true;
  errorLoading = true;
  showNavigation = true;
  isUpdatingNewsletter = false;

  entriesCount = 0;

  activePage = 0;
  startPage = 0;
  endPage: number;
  pageSize = 5;
  maxPageButtons = 5;
  countPageButtonsArr: number[];
  startItem = 0;
  endItem: number;
  numPages: number;
  pageSizeOptions = [5, 10, 15, 20, 25];
  language = 'de';

  constructor(
    @Inject('API_URL') public api: string,
    private es: EntryService,
    private ts: TranslateService
  ) { }

  ngOnInit() {
    
    this.endPage = this.startPage + this.maxPageButtons;
    this.endItem = this.startItem + this.pageSize;

    this.reloadEntries();

    this.ts.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadEntries();
    });

    if (localStorage.getItem('newsletter_home_page_size')) {
      this.updatePageSize(localStorage.getItem('newsletter_home_page_size'));
    }
  }

  reloadEntries() {
    this.isLoading = true;
    this.entries = [];
    this.entriesCount = 0;
    this.newsletters = [];
    this.es.getAllHome(this.ts.currentLang).subscribe(result => {
        if (result.length > 0) {
          this.errorLoading = false;
          this.entries = result;
          const checkNewsletterExistence = newsletter_id => this.newsletters.some( ({_id}) => _id === newsletter_id);

        for (const entry of this.entries) {
          if (!checkNewsletterExistence(entry.newsletter_id._id)) {
            this.newsletters.push(entry.newsletter_id);
          }
        }
        this.updatePages();

        }
      this.isLoading = false;
    },
    error => {
      this.isLoading = false;
      this.errorLoading = true;
    });
  }

  updatePages() {
    this.entriesCount = this.entries.length;
    this.numPages = Math.ceil(this.entriesCount / this.pageSize);
    this.updateCountPageButtonsArr();
    this.setPageFirst();
  }

  updatePageSize(size: string) {
    this.pageSize = parseInt(size, 10);
    localStorage.setItem('newsletter_home_page_size', size);
    this.updatePages();
  }

  setPageFirst() {
    this.activePage = 0;
    this.startItem = 0;
    this.endItem = this.startItem + this.pageSize;
    this.startPage = 0;
    this.endPage = this.activePage + this.maxPageButtons;
  }

  updateCountPageButtonsArr() {
    this.countPageButtonsArr =  Array(this.numPages).fill(0).map((x, i) => i);
  }

  updatePage(page: string) {
    this.activePage = parseInt(page, 10) - 1;
    this.updateItems();
  }

  changePage(count: number) {
    this.activePage += count;

    if (count === (1)) {
      if (!(this.endPage === this.countPageButtonsArr.length)) {
        this.endPage += count;
        if (this.countPageButtonsArr.length > this.maxPageButtons) {
          if ((this.endPage - this.startPage) > this.maxPageButtons) {
            this.startPage += count;
          }
        }
      }
    } else {
      if (!(this.startPage === 0)) {
        this.startPage += count;
        if (this.countPageButtonsArr.length > this.maxPageButtons) {
          if ((this.endPage - this.startPage) > this.maxPageButtons) {
            this.endPage += count;
          }
        }
      }
    }
    this.updateItems();
  }

  updateItems() {
    this.startItem = this.activePage * this.pageSize;
    this.endItem = this.activePage * this.pageSize + this.pageSize;
  }

  ConvertToInt(val) {
    return parseInt(val, 10);
  }

}
