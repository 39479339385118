export class Newsletter {
  constructor(
    public _id: string,
    public name: string,
    public language: string,
    public picture: string,
    public isPublished: boolean,
    public readyForPublishing: boolean,
    public publishDate: Date
   ) {}

}
