import { AuthGuard } from './../auth.guard';
import { EntryRaw } from './entry-raw';
import { EntryFactory } from './EntryFactory';
import { catchError, retry, map } from 'rxjs/operators';
import { Entry } from './Entry';
import { throwError, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  constructor(
    @Inject('API_URL') private api: string,
    private httpClient: HttpClient,
    private ag: AuthGuard) {
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  create(entry: Entry): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .post(`${this.api}/entry`, entry)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getAll(): Observable<Array<Entry>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<EntryRaw[]>(`${this.api}/entrys`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawEntrys => rawEntrys
            .map(rawEntry => EntryFactory.fromObject(rawEntry)),
          ),
          catchError(this.handleError)
        );
    }
  }

  getAllNotPublished(): Observable<Array<Entry>> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<EntryRaw[]>(`${this.api}/entrys/notpublished`)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          map(rawEntrys => rawEntrys
            .map(rawEntry => EntryFactory.fromObject(rawEntry)),
          ),
          catchError(this.handleError)
        );
    }
  }

  getAllHome(language: string): Observable<Array<Entry>> {
    return this.httpClient
      .get<EntryRaw[]>(`${this.api}/entryshome/${language}`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawEntrys => rawEntrys
          .map(rawEntry => EntryFactory.fromObject(rawEntry)),
        ),
        catchError(this.handleError)
      );
  }

  getAllByNewsletter(newsletter_id: string): Observable<Array<Entry>> {
    return this.httpClient
      .get<EntryRaw[]>(`${this.api}/entrysnewsletter/` + newsletter_id)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawEntrys => rawEntrys
          .map(rawEntry => EntryFactory.fromObject(rawEntry)),
        ),
        catchError(this.handleError)
      );
  }

  nameAvailable(entry: string): Observable<Boolean> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
        .get<Boolean>(`${this.api}/entry/check/` + entry.replace('/', '%2F'))
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  remove(id: String): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .delete(`${this.api}/entry/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }
  }

  getOne(id: String): Observable<Entry> {
    return this.httpClient
      .get<EntryRaw>(`${this.api}/entry/${id}`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map(rawEntry => EntryFactory.fromObject(rawEntry),
        ),
        catchError(this.handleError)
      );
  }

  update(entry: Entry): Observable<any> {
    if (this.ag.canActivateMethod()) {
      return this.httpClient
      .put(`${this.api}/entry/${entry._id}`, entry)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
    }
  }
}
