export class ErrorMessage {
  constructor(
    public forControl: string,
    public forValidator: string,
    public text: string
  ) {}
}

export const SubscriberErrorMessages = [
  new ErrorMessage('first_name', 'required', 'HOME.SUBSCRIBER.FAILFORM.REQUIREDFIRSTNAME' ),
  new ErrorMessage('first_name', 'minlength', 'HOME.SUBSCRIBER.FAILFORM.MINLENGTHFIRSTNAME'),
  new ErrorMessage('first_name', 'maxlength', 'HOME.SUBSCRIBER.FAILFORM.MAXLENGTHFIRSTNAME'),
  new ErrorMessage('first_name', 'subscriberfirstnameFormat', 'HOME.SUBSCRIBER.FAILFORM.FORMATFIRSTNAME'),
  new ErrorMessage('name', 'required', 'HOME.SUBSCRIBER.FAILFORM.REQUIREDNAME' ),
  new ErrorMessage('name', 'minlength', 'HOME.SUBSCRIBER.FAILFORM.MINLENGTHNAME'),
  new ErrorMessage('name', 'maxlength', 'HOME.SUBSCRIBER.FAILFORM.MAXLENGTHNAME'),
  new ErrorMessage('name', 'subscribernameFormat', 'HOME.SUBSCRIBER.FAILFORM.FORMATNAME'),
  new ErrorMessage('email', 'required', 'HOME.SUBSCRIBER.FAILFORM.REQUIREDEMAIL' ),
  new ErrorMessage('email', 'email', 'HOME.SUBSCRIBER.FAILFORM.FORMATEMAIL'),
  new ErrorMessage('email', 'mailAvailable', 'HOME.SUBSCRIBER.FAILFORM.AVAILABLEMAIL'),
  new ErrorMessage('checked', 'required', 'HOME.SUBSCRIBER.FAILFORM.REQUIREDCHECKED'),
];
