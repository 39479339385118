import { SubscriberRaw } from './subscriber-raw';
import { Subscriber } from './Subscriber';

export class SubscriberFactory {

  static empty(): Subscriber {
    return new Subscriber('', '', '', '', 'de');
  }

  static fromObject(rawSubscriber: SubscriberRaw | any): Subscriber {
    return new Subscriber(
      rawSubscriber._id,
      rawSubscriber.first_name,
      rawSubscriber.name,
      rawSubscriber.email,
      rawSubscriber.language
    );
  }

}
