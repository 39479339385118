import { NewsletterDetailComponent } from './home/newsletter-detail/newsletter-detail.component';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './shared/authentication/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageComponent } from './language/language.component';
import { LoginbuttonComponent } from './loginbutton/loginbutton.component';
import { SubscribeComponent } from './home/subscribe/subscribe.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DetailComponent } from './home/detail/detail.component';
import { InformComponent } from './home/inform/inform.component';
import { ContactComponent } from './home/contact/contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeDE);
registerLocaleData(localeEN);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LanguageComponent,
    LoginbuttonComponent,
    SubscribeComponent,
    DetailComponent,
    InformComponent,
    ContactComponent,
    NewsletterDetailComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
