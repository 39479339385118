import { faPrint, faHome } from '@fortawesome/free-solid-svg-icons';
import { EntryService } from './../../shared/entry/entry.service';
import { NewsletterFactory } from './../../shared/newsletter/NewsletterFactory';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
@Component({
    selector: 'nl-newsletter-detail',
    templateUrl: './newsletter-detail.component.html'
  })
  export class NewsletterDetailComponent implements OnInit {

    faHome = faHome;
    faPrint = faPrint;

    newsletter = NewsletterFactory.empty();
    entries = [];
    entriesCount = 0;
    activePage = 0;
    startPage = 0;
    endPage: number;
    pageSize = 5;
    maxPageButtons = 5;
    countPageButtonsArr: number[];
    startItem = 0;
    endItem: number;
    numPages: number;
    pageSizeOptions = [5, 10, 15, 20, 25];

    constructor(
        @Inject('API_URL') public api: string,
        private route: ActivatedRoute,
        private es: EntryService
      ) { }

    ngOnInit() {

        const data = this.route.snapshot.data;
        if (data['newsletter']) {
            this.newsletter = data['newsletter'];
            this.es.getAllByNewsletter(this.newsletter._id).subscribe({
                next: (entries) => {
                  if (entries.length > 0) {
                    this.entries = entries;
                    this.updatePages();
                  }
                },
                error: (msg) => {
                  this.entries= [];
                }
            });
        }
    }

    updatePages() {
        this.entriesCount = this.entries.length;
        this.numPages = Math.ceil(this.entriesCount / this.pageSize);
        this.updateCountPageButtonsArr();
        this.setPageFirst();
    }

    updatePageSize(size: string) {
        this.pageSize = parseInt(size, 10);
        localStorage.setItem('newsletter_home_page_size', size);
        this.updatePages();
    }

    updateCountPageButtonsArr() {
        this.countPageButtonsArr =  Array(this.numPages).fill(0).map((x, i) => i);
    }

    setPageFirst() {
        this.activePage = 0;
        this.startItem = 0;
        this.endItem = this.startItem + this.pageSize;
        this.startPage = 0;
        this.endPage = this.activePage + this.maxPageButtons;
    }

    updatePage(page: string) {
        this.activePage = parseInt(page, 10) - 1;
        this.updateItems();
      }
    
      changePage(count: number) {
        this.activePage += count;
    
        if (count === (1)) {
          if (!(this.endPage === this.countPageButtonsArr.length)) {
            this.endPage += count;
            if (this.countPageButtonsArr.length > this.maxPageButtons) {
              if ((this.endPage - this.startPage) > this.maxPageButtons) {
                this.startPage += count;
              }
            }
          }
        } else {
          if (!(this.startPage === 0)) {
            this.startPage += count;
            if (this.countPageButtonsArr.length > this.maxPageButtons) {
              if ((this.endPage - this.startPage) > this.maxPageButtons) {
                this.endPage += count;
              }
            }
          }
        }
        this.updateItems();
      }

      updateItems() {
        this.startItem = this.activePage * this.pageSize;
        this.endItem = this.activePage * this.pageSize + this.pageSize;
      }
    
      ConvertToInt(val) {
        return parseInt(val, 10);
      }
  }