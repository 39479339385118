import { AuthService } from './authentication/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.getToken()) {
      if (!this.authService.isTokenExpired()) {
        return true;
      }
    }

    this.router.navigate(['/user/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }

  canActivateMethod() {
    if (this.authService.getToken()) {
      if (!this.authService.isTokenExpired()) {
        return true;
      }
    }

    this.router.navigate(['/user/login']);
    return false;
  }
}
