<div class="col-lg-5 container form-layout-custom">
  <div *ngIf="failSave" class="container alert alert-danger col-md-12 margin-top-20" role="alert">
      {{ 'HOME.SUBSCRIBER.FAILFORM.SAVE' | translate }}
  </div>
  <div *ngIf="successSubscribe" class="container alert alert-success col-md-12 margin-top-20" role="alert">
    {{ 'HOME.SUBSCRIBER.SUCCESSSUBSCRIBE' | translate }}
</div>
  <div class="heading-form">
      {{ 'HOME.SUBSCRIBER.HEADING' | translate }}
    <a routerLink="/home/inform" class="note-heading-subscribe">
      {{ 'HOME.SUBSCRIBER.INFORM' | translate }}
    </a>
  </div>
    <form
    [formGroup]="subscriberForm"
    (ngSubmit)="submitFormSubscriber()">
      <div class="form-group">
        <label >{{ 'HOME.SUBSCRIBER.FIRSTNAME' | translate }}</label>
        <input [ngClass]="{'is-invalid': errors.first_name}" formControlName="first_name" class="form-control">
        <div *ngIf="errors.first_name" class="invalid-feedback">
          {{ errors.first_name | translate}}
        </div>
      </div>
      <div class="form-group">
        <label >{{ 'HOME.SUBSCRIBER.NAME' | translate }}</label>
        <input [ngClass]="{'is-invalid': errors.name}" formControlName="name" class="form-control">
        <div *ngIf="errors.name" class="invalid-feedback">
          {{ errors.name | translate}}
        </div>
      </div>
      <div class="form-group">
        <label >{{ 'HOME.SUBSCRIBER.EMAIL' | translate }}</label>
        <input [ngClass]="{'is-invalid': errors.email}" formControlName="email" class="form-control">
        <div *ngIf="errors.email" class="invalid-feedback">
          {{ errors.email | translate}}
        </div>
      </div>
      <div class="form-group col-md-5 no-padding-left">
          <label >{{ 'HOME.SUBSCRIBER.CHOOSELANGUAGE' | translate }}</label>
          <select [ngClass]="{'is-invalid': errors.language}"
          formControlName="language" class="form-control">
            <option *ngFor="let l of languageOptions" [ngValue]="l">{{ l }}</option>
          </select>
          <div *ngIf="errors.language" class="invalid-feedback">
            {{ errors.language | translate}}
          </div>
      </div>
      <div class="margin-top-20 margin-bottom-10" [innerHTML]="'HOME.SUBSCRIBER.TEXTEXPLAIN' | translate">
      </div>
      <div class="form-group">
        <input [ngClass]="{'is-invalid': errors.checked}" type="checkbox" formControlName="checked" class="input-dataprotection">
        <div class="text-dataprotection" [innerHTML]="'HOME.SUBSCRIBER.CHECKED' | translate">
        </div>
        <div *ngIf="errors.checked" class="invalid-feedback">
          {{ errors.checked | translate}}
        </div>
      </div>
      <button type="submit" [disabled]="subscriberForm.invalid || subscriberForm.pending" class="btn btn-dark margin-right-15 margin-top-10">
          <fa-icon [icon]="faSave"></fa-icon>
        {{ 'HOME.SUBSCRIBER.CREATE' | translate }}<fa-icon *ngIf="isLoadingSubscribe" [icon]="faSync" class="spin"></fa-icon></button>
      <button type="button" routerLink="/" class="btn btn-info margin-top-10">{{ 'HOME.SUBSCRIBER.TOLANDINGPAGE' | translate }}</button>
    </form>
</div>
