<div class="completewrapper">
  <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top">
    <div class="container-navbar">
        <div class="navbar-header">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand bonares-icon" href="https://www.bonares.de"></a>
        </div>

        <div class="navbar-head">
          <div class=" collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link" href="https://www.bonares.de">Home</a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown">Newsroom<fa-icon [icon]="faAngleDown" class="news-icon"></fa-icon></a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="https://www.bonares.de/app#/news">News</a>
                  <a class="dropdown-item" routerLink="/home" routerLinkActive="active">Newsletter</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.bonares.de/event">Events</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.bonares.de/public-projects">{{ 'HOME.PROJECTS' | translate }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://datenzentrum.bonares.de/research-data.php">{{ 'HOME.RESEARCHDATA' | translate }}</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" id="soilDropdown" role="button" data-toggle="dropdown">{{ 'HOME.SOILFUNCTIONS' | translate }}<fa-icon [icon]="faAngleDown" class="news-icon"></fa-icon></a>
              <div class="dropdown-menu" aria-labelledby="soilDropdown">
                <a class="dropdown-item" href="{{ 'HOME.OVERVIEWLINK' | translate }}">{{ 'HOME.OVERVIEW' | translate }}</a>
                <a class="dropdown-item" href="{{ 'HOME.MODELLINK' | translate }}">{{ 'HOME.MODEL' | translate }}</a>
                <a class="dropdown-item" href="{{ 'HOME.QELINK' | translate }}">{{ 'HOME.QE' | translate }}</a>
                <a class="dropdown-item" href="{{ 'HOME.SALINK' | translate }}">{{ 'HOME.SA' | translate }}</a>
                <a class="dropdown-item" href="{{ 'HOME.FSLINK' | translate }}">{{ 'HOME.FS' | translate }}</a>
                <a class="dropdown-item" href="{{ 'HOME.GOLINK' | translate }}">{{ 'HOME.GO' | translate }}</a>
              </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.bonares.de/services">Services</a>
              </li>
              <li *ngIf="(isLoggedIn$ | async) === true" class="nav-item">
                <a class="nav-link" routerLink="/admin/dashboard" routerLinkActive="active">{{ 'ADMIN.DASHBOARD.ADMINAREATEXT' | translate }}</a>
              </li>
            </ul>
          </div>
          <div class="login-language">
            <nl-loginbutton>
            </nl-loginbutton>
            <nl-language>
            </nl-language>
          </div>
        </div>
    </div>
  </nav>
  <div class="outletwrapper">
      <router-outlet>
        </router-outlet>
  </div>


  <div class="footer">
    <div class="inner-wrapper margin-top-30">
      <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12 float-left margin-top-40 margin-bottom-40">
        <a href="https://www.bmbf.de/">
          <img src="{{ 'APP.FOOTER.BMBF' | translate }}" style="display: block;">
        </a>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12 float-left">
        <ul>
          <li>
            <a href="{{ 'APP.FOOTER.IMPRINT_LINK' | translate }}">
              <div class="menu-text-padding-tab">
                  {{ 'APP.FOOTER.IMPRINT' | translate }}
              </div>
            </a>
          </li>
          <li>
            <a href="{{ 'APP.FOOTER.PRIVACYPOLICY_LINK' | translate }}">
              <div class="menu-text-padding-tab">
                  {{ 'APP.FOOTER.PRIVACYPOLICY' | translate }}
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.bonares.de/contact-de">
              <div class="menu-text-padding-tab">
                  {{ 'APP.FOOTER.CONTACT' | translate }}
              </div>
            </a>
          </li>
          <li>
            <a href="{{ 'APP.FOOTER.SITEMAP_LINK' | translate }}">
              <div class="menu-text-padding-tab">
                  {{ 'APP.FOOTER.SITEMAP' | translate }}
              </div>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/BonaRes4u" class="twitter-wrapper"></a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>

