<div class="col-lg-5 container form-layout-custom">
  <div *ngIf="successSubscribe" class="container alert alert-success col-md-12 margin-top-20" role="alert">
    {{ 'HOME.INFORM.SUCCESSSUBSCRIBE' | translate }}
  </div>
  <div *ngIf="successUnsubscribe" class="container alert alert-warning col-md-12 margin-top-20" role="alert">
    {{ 'HOME.INFORM.SUCCESSUNSUBSCRIBE' | translate }}
  </div>
  <div class="heading-form">
      {{ 'HOME.INFORM.HEADING' | translate }}
  </div>
  <div>
    {{ 'HOME.INFORM.TEXTEXPLAIN' | translate }}
  </div>
  <div class="entry-inform">
    <fa-icon [icon]="faChevronCircleRight" class="icon-inform"></fa-icon> {{ 'HOME.INFORM.FIRSTPOINT' | translate }}
  </div>
  <div class="entry-inform">
    <fa-icon [icon]="faChevronCircleRight" class="icon-inform"></fa-icon> {{ 'HOME.INFORM.SECONDPOINT' | translate }}
  </div>
  <div class="entry-inform">
    <fa-icon [icon]="faChevronCircleRight" class="icon-inform"></fa-icon> {{ 'HOME.INFORM.THIRDPOINT' | translate }}
  </div>
    <button *ngIf="!successSubscribe" type="button" routerLink="/home/subscribe" class="btn btn-dark margin-right-15 margin-top-20">
      <fa-icon [icon]="faPencilAlt"></fa-icon>
    {{ 'HOME.INFORM.SUBSCRIBE' | translate }}</button>
  <button type="button" routerLink="/" class="btn btn-info margin-top-20">{{ 'HOME.SUBSCRIBER.TOLANDINGPAGE' | translate }}</button>
</div>
