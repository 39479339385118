import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from '../../../../node_modules/rxjs';
import jwt_decode, { JwtPayload } from 'jwt-decode';

type JwtPayloadCustom = Omit<JwtPayload, 'aud'> & { aud: string };

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn = new BehaviorSubject<boolean>(false); // {1}

  constructor(
    @Inject('API_URL') private api: string,
    private http: HttpClient) {
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(error);
  }

  login(username: string, password: string ) {
      return this.http.post(`${this.api}/users/login`, {'username': username, 'password': password})
        .pipe(
          map(response =>  this.setSession(response)),
          catchError(this.handleError) // then handle the error
        );
  }

  private setSession(authResult) {

      localStorage.setItem('id_token', authResult.idToken);
      this.loggedIn.next(true);
  }

  logout() {
      localStorage.removeItem('id_token');
      this.loggedIn.next(false);
  }

  getExpiration() {

    const decoded = jwt_decode<JwtPayloadCustom>(this.getToken());

    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }

    const date = this.getExpiration();
    if (date === undefined) {
      return true;
    }
    if ((date.valueOf() > new Date().valueOf())) {
      return false;
    } else {
      this.loggedIn.next(false);
      return true;
    }
  }

  getToken(): string {
    return localStorage.getItem('id_token');
  }
}
