import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nl-language',
  templateUrl: './language.component.html',
  styles: []
})
export class LanguageComponent {
    constructor(public translate: TranslateService,
      @Inject('local') private local: boolean) {

      if (this.getCookie('apos_language')) {
        this.translate.setDefaultLang(this.getCookie('apos_language'));
        this.translate.use(this.getCookie('apos_language'));
      } else {
        this.translate.setDefaultLang('en');
        this.setCookie('apos_language', 'en');
        this.translate.use('en');
      }
      }

      updateLang(englishSelected) {
        this.translate.use(englishSelected ? 'en' : 'de');
        this.setCookie('apos_language', englishSelected ? 'en' : 'de');
      }

      getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
      }

      setCookie(name: string, val: string) {
        const value = val;
        // Set it
        if (!this.local) {
          document.cookie = name + '=' + value + ';Domain=.bonares.de; expires=Session; path=/';
        } else {
          document.cookie = name + '=' + value + ';expires=Session; path=/';
        }
      }

}
