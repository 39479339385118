export class Subscriber {
  constructor(
    public _id: string,
    public first_name: string,
    public name: string,
    public email: string,
    public language: string
   ) {}

}
