import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NavigationService } from './shared/navigation.service';
import { Observable } from 'rxjs';
import { AuthService } from './shared/authentication/auth.service';
import { Component, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nl-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  faAngleDown = faAngleDown;
  
  navigationData: any;

  constructor(private authService: AuthService,
    private ns: NavigationService,
    private ts: TranslateService) { }

  ngOnInit() {
    this.ts.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateNavigation();
    });
    
    if (!this.authService.isTokenExpired()) {
      this.authService.loggedIn.next(true);
    } else {
      this.authService.loggedIn.next(false);
    }

     this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  updateNavigation() {
    this.ns.getNavigation(this.ts.currentLang).subscribe({
      next: (data: any) => {
        console.log(data);
        this.navigationData = data;
      }
    });
  }
}
